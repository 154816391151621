import $ from "jquery";

type AnimationFrame = () => void;

const animationFrames: AnimationFrame[] = [];

$(function () {
  const mobileBreakpoint = 800;
  const topNav = $("header nav").get(0);
  const bottomNav = $("footer").get(0);

  let topMax = null;
  let scrollOffset = null;
  let bottomOffset = null;
  let wWidth = null,
    wHeight = null;

  function loop() {
    animationFrames.forEach((fn) => fn());
    requestAnimationFrame(loop);
  }
  loop();

  function opacify(elem: HTMLElement, opacity: number) {
    elem.style.opacity = `${opacity}`;
    if (opacity <= 0) {
      elem.style.visibility = "hidden";
    } else {
      elem.style.visibility = "visible";
    }
  }

  function transparentizeNav() {
    // Resize window
    if (wWidth != window.innerWidth || wHeight != window.innerHeight) {
      wWidth = window.innerWidth;
      wHeight = window.innerHeight;
      topMax =
        $("header .content").position().top -
        ($(topNav).height() + $(topNav).position().top);
      scrollOffset = null;
    }

    // Scroll
    if (scrollOffset != window.pageYOffset) {
      scrollOffset = window.pageYOffset;
      bottomOffset =
        document.documentElement.scrollHeight - (scrollOffset + wHeight);

      // Top nav
      if (wWidth <= mobileBreakpoint || scrollOffset < topMax / 3) {
        opacify(topNav, 1.0);
      } else if (scrollOffset < topMax) {
        opacify(topNav, 1 - (scrollOffset - topMax / 3) / ((topMax / 3) * 2));
      } else {
        opacify(topNav, 0.0);
      }

      // Bottom nav
      if (wWidth <= mobileBreakpoint) {
        opacify(bottomNav, 1.0);
      } else if (bottomOffset >= 120) {
        opacify(bottomNav, 0.0);
      } else if (bottomOffset > 60) {
        opacify(bottomNav, 1.0 - (bottomOffset - 60) / 60);
      } else {
        opacify(bottomNav, 1.0);
      }
    }
  }

  animationFrames.push(transparentizeNav);
  transparentizeNav();
});
